import React, { useEffect } from "react"
import Layout from "../components/layout"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import { DefaultGtag } from '../utils/misc'


const mainStyles = {
    display: "flex",
    flexDirection: "column"
}

const pageStyle = {
    width: "54rem",
    margin: "auto",
    marginTop: "7rem",
    padding: "1rem 2rem",
    marginBottom: "3rem",
    maxWidth: "100%"
    // maxWidth: "calc(100% - 4rem)"
}

const ToSPage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(function () {
            // need delay, otherwise not working on firefox...
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }, 100);
    }, [])

    return (
        <Layout>
            <main style={mainStyles}>
                <div style={pageStyle}>
                    <h1 style={{ textAlign: "center" }}>TERMS AND CONDITIONS</h1>

                    <p>Welcome to Take A Seat’s terms and conditions! We are a start-up
                        located in Zürich, Switzerland.</p>
                    <p>These terms and conditions (“Terms”) outline the rules and
                        regulations for the use of Take A Seat’s Platform. Please read these
                        Terms carefully. By accessing this website, you acknowledge that you
                        have read, understood, and agree to be bound by these Terms. Do not
                        continue to use Take A Seat's website if you do not accept all of
                        the terms and conditions stated on this page.</p>
                    <p>
                        The following terminology applies to these Terms and Conditions,
                        Privacy Statement and Disclaimer Notice and any or all Agreements:
                        <ol>
                            <li>“Client”, "User", “You” and “Your” refers to you, the person
                                accessing this website and accepting the Company’s terms and
                                conditions.</li>
                            <li>“The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to
                                our Company, Take A Seat.</li>
                            <li>“Party”, “Parties”, or “Us”, refers to both the Client and
                                ourselves, or either the Client or ourselves.</li>
                        </ol>
                    </p>
                    <p>All terms refer to the offer, acceptance and consideration of payment
                        necessary to undertake the process of our assistance to the Client
                        in the most appropriate manner, whether by formal meetings of a
                        fixed duration, or any other means, for the express purpose of
                        meeting the Client’s needs in respect of provision of the Company’s
                        stated services/products, in accordance with and subject to,
                        prevailing law of Zurich. Any use of the above terminology or other
                        words in the singular, plural, capitalisation and/or he/she or they,
                        are taken as interchangeable and therefore as referring to the same.</p>
                    <h2>Using Take A Seat</h2>
                    <p>You may use Take A Seat only if you can form a binding contract with
                        Take A Seat, and only in compliance with these Terms and Conditions
                        and the applicable laws. When you create an account with us, you
                        must provide us with accurate, current, and complete information.
                        Any use of access by anyone under the age of 16 is prohibited, and
                        certain content offerings may have additional requirements or
                        restrictions.</p>

                    <h2>License to Use</h2>
                    <p>Subject to these Terms and any other agreements between you and us,
                        we grant you a limited, personal, non-exclusive, non-transferable,
                        and revocable license to use our Services. You may view, download
                        and/or print content from the Take A Seat Platform for your own
                        personal and non-commercial use, unless you obtain written consent
                        by Take A Seat. Using our Services does not give you ownership of
                        any intellectual property rights in our Services or content you
                        access. Unless otherwise stated, all intellectual property rights
                        are reserved to Take A Seat.
                        You must not:
                        <ol>
                            <li>Republish material from the Take A Seat platform</li>
                            <li>Sell, rent or sub-license material from the Take A Seat
                                platform</li>
                            <li>Reproduce, duplicate or copy material from the Take A Seat
                                platform</li>
                        </ol>
                    </p>

                    <h2>Content Offerings</h2>
                    <p>Take A Seat provides its users with a platform where they can create
                        websites and other digital tools for their own services in the food
                        and hospitality industry. Therefore, Take A Seat is not itself an
                        operator or provider of those services. Users are responsible for
                        understanding and complying with all laws, rules and regulations
                        that may apply to the services listed on the websites or other
                        digital tools hosted by Take A Seat.</p>
                    <p>Take A Seat reserves the right to decide, in its sole discretion,
                        whether a service will be published on the Take A Seat platform.
                        Once published, a service may be removed at our sole discretion to
                        ensure the quality of the platform.</p>

                    <h2>User Content</h2>
                    <p>By creating, uploading, posting, sending, receiving, storing, or
                        otherwise making available any User Content on or through the Take A
                        Seat Platform, you grant Take A Seat a non-exclusive, worldwide,
                        royalty-free, irrevocable, perpetual, sub-licensable and
                        transferable license to such User Content to access, use, store,
                        copy, modify, prepare derivative works of, distribute, publish,
                        transmit, stream, broadcast, and otherwise exploit in any manner
                        such User Content to provide and/or promote the Take A Seat
                        Platform, in any media or platform. Unless you provide specific
                        consent, Take A Seat does not claim any ownership rights in any User
                        Content and nothing in these Terms will be deemed to restrict any
                        rights that you may have to use or exploit your content.</p>
                    <p>You are solely responsible for the User Content you make available on
                        the Take A Seat Platform, and you represent and warrant that you
                        have all the rights, licenses, consents and releases necessary to
                        publish it and grant Take A Seat the content rights discussed in
                        these terms. You also ensure the User Content is not fraudulent,
                        false, misleading, deceptive, defamatory, obscene, or offensive.</p>
                    <h2>Hyperlinking to Take A Seat</h2>

                    <p>You may link to our home page or to other Website information so long
                        as the link: (a) is not in any way misleading; (b) does not falsely
                        imply sponsorship, endorsement or approval of the linking party and
                        its products or services; and (c) fits within the context of the
                        linking party's site.</p>
                    <h2>Iframes</h2>
                    <p>Without prior approval and express written permission, you may not
                        create frames around our Web pages or use other techniques that
                        alter in any way the visual presentation or appearance of our
                        Website.</p>
                    <h2>Disclaimer</h2>
                    <p>If you choose to use the Take A Seat Platform, you do so voluntarily
                        and at your sole risk. The Take A Seat Platform is provided “as is”,
                        without warranty of any kind, either express or implied.</p>
                    <p>You agree that the services hosted on Take A Seat may carry inherent
                        risk, and by participating in such services, you choose to assume
                        those risks voluntarily. For example, some services listed by
                        restaurants may carry risk of illness, bodily injury, disability, or
                        death, and you freely and willfully assume those risks by choosing
                        to participate in those services.</p>

                    <h2>Limitation of Liability</h2>
                    <p>To the maximum extent permitted by applicable law, we exclude all
                        representations, warranties and conditions relating to our website
                        and the use of this website (including, without limitation, any
                        warranties implied by law in respect of satisfactory quality).</p>
                    <p>The limitations and exclusions of liability set out in this Section
                        and elsewhere in this disclaimer: (a) are subject to the preceding
                        paragraph, and (b) govern all liabilities arising under the
                        disclaimer or in relation to the subject matter of this disclaimer,
                        including liabilities arising in contract, in tort (including
                        negligence) and for breach of statutory duty.</p>
                    <p>We will not, in any case, be liable for any loss or damage of any
                        nature.</p>

                    <h2>Indemnification</h2>
                    <p>You agree to indemnify, defend, and hold harmless Take A Seat against
                        all claims, liabilities, expenses, and damages, including Attorneys’
                        fees arising out of (a) your use or misuse of our Services; or (b)
                        your violation of the rights of any other person or entity.</p>
                </div>
            </main>
        </Layout>
    )
}

export default ToSPage

export const Head = () => <>
  <DefaultGtag />
    <title>Take a Seat - Terms of service</title>
    {/* <link rel="canonical" href="https://takeaseat.io/terms-of-service/" /> */}
    <link rel="alternate" hreflang="x-default" href="https://takeaseat.io/terms-of-service/" />
    <link rel="alternate" hreflang="fr" href="https://takeaseat.io/fr/terms-of-service/" />
    <link rel="alternate" hreflang="de" href="https://takeaseat.io/de/terms-of-service/" />
    <link rel="alternate" hreflang="es" href="https://takeaseat.io/es/terms-of-service/" />
    <meta name="description" content="Terms of service of Take a Seat." />
</>

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;